// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
import { getFactory } from '../operations/get';
import { convertItemToConversation } from './convertItemToConversation';
export const createCreateConversationFunction = (client, modelIntrospection, conversationRouteName, conversationModel, conversationMessageModel, getInternals) => async () => {
    const get = getFactory(client, modelIntrospection, conversationModel, 'CREATE', getInternals);
    const { data, errors } = await get();
    return {
        data: convertItemToConversation(client, modelIntrospection, data?.id, conversationRouteName, conversationMessageModel, getInternals),
        errors,
    };
};
