// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
import { listFactory } from '../operations/list';
import { convertItemToConversation } from './convertItemToConversation';
export const createListConversationsFunction = (client, modelIntrospection, conversationRouteName, conversationModel, conversationMessageModel, getInternals) => async (input) => {
    const list = listFactory(client, modelIntrospection, conversationModel, getInternals);
    const { data, nextToken, errors } = await list(input);
    return {
        data: data.map((datum) => {
            return convertItemToConversation(client, modelIntrospection, datum.id, conversationRouteName, conversationMessageModel, getInternals);
        }),
        nextToken,
        errors,
    };
};
