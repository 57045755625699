// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
import { getFactory } from '../operations/get';
import { convertItemToConversation } from './convertItemToConversation';
export const createGetConversationFunction = (client, modelIntrospection, conversationRouteName, conversationModel, conversationMessageModel, getInternals) => async ({ id }) => {
    const get = getFactory(client, modelIntrospection, conversationModel, 'GET', getInternals);
    const { data, errors } = await get({ id });
    return {
        data: data
            ? convertItemToConversation(client, modelIntrospection, data.id, conversationRouteName, conversationMessageModel, getInternals)
            : data,
        errors,
    };
};
