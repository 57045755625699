// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
import { createListMessagesFunction } from './createListMessagesFunction';
import { createOnMessageFunction } from './createOnMessageFunction';
import { createSendMessageFunction } from './createSendMessageFunction';
export const convertItemToConversation = (client, modelIntrospection, conversationId, conversationRouteName, conversationMessageModel, getInternals) => {
    if (!conversationId) {
        throw new Error(`An error occurred converting a ${conversationRouteName} conversation: Missing ID`);
    }
    return {
        id: conversationId,
        onMessage: createOnMessageFunction(client, modelIntrospection, conversationId, conversationRouteName, getInternals),
        sendMessage: createSendMessageFunction(client, modelIntrospection, conversationId, conversationRouteName, getInternals),
        listMessages: createListMessagesFunction(client, modelIntrospection, conversationId, conversationMessageModel, getInternals),
    };
};
